






























































































































import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import { UserApi } from "@/networks/UserApi";
import moment from "moment-jalaali";
import Office from "../../components/inputs/office.vue";
import FileUploader from "@/views/components/inputs/fileUploader.vue";

@Component({
  components: {
    FileUploader,
    DatetimePicker,
    office: Office
  }
})
export default class AddUser extends Vue {
  private types = [
    {
      text: "گذرانده",
      value: "0"
    },
    {
      text: "معافیت تحصیلی",
      value: "1"
    },
    {
      text: "مشمول",
      value: "3"
    },
    {
      text: "دیگر",
      value: "2"
    }
  ];
  private marital = [
    {
      text: "مجرد",
      value: "0"
    },
    {
      text: "متاهل",
      value: "1"
    }
  ];

  private form: Record<string, unknown> = {};

  private errors: Record<string, Array<string>> = {};

  private datePopup = false;
  private details = false;

  mounted() {
    if (this.$route.params.id) {
      this.getUser(this.$route.params.id);
    }
  }

  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      if (typeof form.avatar == "string") {
        delete form.avatar;
      }
      let res: AxiosResponse;
      if (!this.$route.params.id) {
        res = await new UserApi().store(form);
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.form = {};
      } else {
        const data_form = {
          _method: "put",
          fname: this.form.fname,
          lname: this.form.lname,
          mobile: this.form.mobile,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          national_code: this.form.national_code,
          birthday: moment(this.form.birthday, "jYYYY/jMM/jDD").format(
            "YYYY/MM/DD"
          ),
          avatar: this.form.avatar
        };
        res = await new UserApi().edit(this.$route.params.id, data_form);
      }
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async getUser(id: string): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getUser(id);
      this.form = {
        ...res.data.data.user_detail,
        ...res.data.data
      };
      this.form.birthday &&
        (this.form.birthday = moment(this.form.birthday, "YYYY-MM-DD").format(
          "jYYYY/jMM/jDD"
        ));
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
